html,
body,
#app,
#app>div {
    height: 100%
}


body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5vh;
    color: white;
    background-image: url('./pages/img/origin.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-origin: content-box;
    background-clip: content-box;
    background-color: black;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

li {
    color: white;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a,
a:link,
a:hover,
a:visited,
a:active {
    text-decoration: none;
    color: rgba(77, 30, 15, 1);
}

ul {
    list-style-type: none;
}

a {
    font-weight: bold;
    color: rgba(77, 30, 15, 1);
    transition: color 200ms ease;
}

a:hover {
    color: white;
    transition: color 400ms ease;
}

.author {
    color: red;
}